import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './ListItem.less';

class ListItem extends PureComponent {
	render() {
		const { title, onClick } = this.props;
		return (
			<li className={ styles.container } onClick={ onClick }>
				<div className={ styles.title }>{ title }</div>
				<div className={ styles.desc }>
					{ this.props.children }
				</div>
			</li>
		);
	}
}

ListItem.propTypes = {
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export default ListItem;