exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3OkkN {\n  margin-top: 0 !important;\n  padding: 10px !important;\n}\n._3OkkN .wCELj {\n  word-wrap: break-word;\n  white-space: pre-wrap;\n  padding-top: 5px;\n  padding-right: 10px;\n}\n._3OkkN .UlhGg {\n  padding: 15px 10px;\n}\n._3OkkN .UlhGg .PDSzw {\n  position: relative;\n  padding: 0 15px;\n  cursor: pointer;\n}\n._3OkkN .UlhGg ._3CLUF {\n  margin-right: 20px;\n}\n._3OkkN .UlhGg ._3CLUF ul {\n  padding-left: 0 !important;\n}\n", ""]);

// Exports
exports.locals = {
	"list": "_3OkkN",
	"remark": "wCELj",
	"menu": "UlhGg",
	"button": "PDSzw",
	"dropdownMenu": "_3CLUF"
};