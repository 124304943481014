import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'Components/modal';
import Button from 'Components/button';
import Tab from 'Components/tab';
import loc from 'Components/languages';
import DatePicker from 'Components/date-picker';
import { convertDateForApi, setDefaultDateFrom } from 'Helpers/helpers';
import styles from './ModalAdvancedSearch.less';

class ModalAdvancedSearch extends PureComponent {
	constructor() {
		super();
		this.state = {
			orderBy: 'descending',
			shop: 'currentShop',
			sortBy: 'transactionDate',
			dateFrom: setDefaultDateFrom(),
			dateTo: new Date(),
			orderStatus: 'all',
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 300px)',
				right: 'calc(50% - 300px)',
				maxHeight: '538px',
				padding: 0,
			},
		};
		this.tabs = {
			orderBy: [
				'ascending',
				'descending',
			],
			shop: [
				'currentShop',
				'allShop',
			],
			sortBy: [
				'amount',
				'transactionDate',
			],
			orderStatus: [
				'all',
				'complete',
				'incomplete',
				'cancel',
			],
		};
		this.onChangeOrderByHandler = this.onChangeHandler.bind(this, 'orderBy');
		this.onChangeShopHandler = this.onChangeHandler.bind(this, 'shop');
		this.onChangeSortByHandler = this.onChangeHandler.bind(this, 'sortBy');
		this.onChangeDateFromHandler = this.onChangeHandler.bind(this, 'dateFrom');
		this.onChangeDateToHandler = this.onChangeHandler.bind(this, 'dateTo');
		this.onChangeOrderStatusHandler = this.onChangeHandler.bind(this, 'orderStatus');
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	onChangeHandler(key, value) {
		let newState = {};
		switch (key) {
		case 'dateFrom':
		case 'dateTo':
			value = value ? value : new Date();
			localStorage.setItem(key, value);
			break;
		}
		newState[key] = value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		const { onChange } = this.props;
		const { orderBy, shop, sortBy, dateFrom, dateTo, orderStatus } = this.state;
		e.preventDefault();
		let data = {
			sort_direction: orderBy === 'ascending' ? 'asc' : 'desc',
			sort_column: sortBy === 'amount' ? 'amount' : 'date',
			is_current_shop: shop === 'currentShop',
			transaction_start_date: convertDateForApi(dateFrom, '00:00:00'),
			transaction_end_date: convertDateForApi(dateTo, '23:59:59'),
			transaction_status: orderStatus,
		};
		onChange(data);
	}

	render() {
		const { isOpen, onToggle, showShopFilter } = this.props;
		const { orderBy, shop, sortBy, dateFrom, dateTo, orderStatus } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.advancedSearch }
				style={ this.modalStyle }
			>
				<form onSubmit={ this.onSubmitHandler }>
					<div className={ styles.title }>
						{ loc.sorting }
					</div>

					<div className={ classnames('uk-flex', styles.row) }>
						<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
							{ loc.sortBy }
						</div>
						<div className={ classnames('uk-width-2-3', styles.value) }>
							<Tab
								tabs={ this.tabs.sortBy }
								current={ sortBy }
								onChange={ this.onChangeSortByHandler }
								buttonClassName={ styles.tabButton }
							/>
						</div>
					</div>

					<div className={ classnames('uk-flex', styles.row) }>
						<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
							{ loc.orderBy }
						</div>
						<div className={ classnames('uk-width-2-3', styles.value) }>
							<Tab
								tabs={ this.tabs.orderBy }
								current={ orderBy }
								onChange={ this.onChangeOrderByHandler }
								buttonClassName={ styles.tabButton }
							/>
						</div>
					</div>

					{
						showShopFilter && (
							<div className={ classnames('uk-flex', styles.row) }>
								<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
									{ loc.shop }
								</div>
								<div className={ classnames('uk-width-2-3', styles.value) }>
									<Tab
										tabs={ this.tabs.shop }
										current={ shop }
										onChange={ this.onChangeShopHandler }
										buttonClassName={ styles.tabButton }
									/>
								</div>
							</div>
						)
					}

					<div className={ styles.title }>
						{ loc.filtering }
					</div>

					<div className={ classnames('uk-flex', styles.row) }>
						<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
							{ loc.dateFrom }
						</div>
						<div className={ classnames('uk-width-2-3', styles.value) }>
							<DatePicker
								value={ dateFrom }
								onChange={ this.onChangeDateFromHandler }
							/>
						</div>
					</div>

					<div className={ classnames('uk-flex', styles.row) }>
						<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
							{ loc.dateTo }
						</div>
						<div className={ classnames('uk-width-2-3', styles.value) }>
							<DatePicker
								value={ dateTo }
								onChange={ this.onChangeDateToHandler }
							/>
						</div>
					</div>

					<div className={ classnames('uk-flex', styles.row) }>
						<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
							{ loc.orderStatus }
						</div>
						<div className={ classnames('uk-width-2-3', styles.value) }>
							<Tab
								tabs={ this.tabs.orderStatus }
								current={ orderStatus }
								onChange={ this.onChangeOrderStatusHandler }
								buttonClassName={ styles.tabButton }
							/>
						</div>
					</div>

					<div className={ styles.button }>
						<Button
							text={ loc.updateResults }
							theme="primary"
							type="submit"
							onClick={ this.onSubmitHandler }
						/>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalAdvancedSearch.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	showShopFilter: PropTypes.bool,
};

export default ModalAdvancedSearch;