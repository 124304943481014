import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';
import zhTW from 'rc-calendar/lib/locale/zh_TW';
import zhCN from 'rc-calendar/lib/locale/zh_CN';
import enUS from 'rc-calendar/lib/locale/en_US';
import 'rc-calendar/assets/index.css';
import './DatePicker.css';

class DatePicker extends PureComponent {
	constructor() {
		super();
		this.state = {
			open: false,
			value: moment(),
			isMouseDown: false,
		};
		this.dateFormat = 'YYYY-MM-DD';
		this.calendarContainerRef = React.createRef();
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onOpenChangeHandler = this.onOpenChangeHandler.bind(this);
		this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
		this.onFocusHandler = this.onFocusHandler.bind(this);
		this.renderCalendar = this.renderCalendar.bind(this);
		this.renderInput = this.renderInput.bind(this);
	}

	componentDidMount() {
		const { value } = this.props;
		this.setState({
			value: value ? moment(value) : null,
		});
	}

	componentDidUpdate(prevProps) {
		const value = this.props.value;
		const prevValue = prevProps.value;
		if (value !== prevValue) {
			this.setState({
				value: value ? moment(value) : null,
			});
		}
	}

	onChangeHandler(value) {
		this.setState({
			value,
		});
		if (value) {
			this.props.onChange(value.toDate());
		} else {
			this.props.onChange(null);
		}
	}

	onOpenChangeHandler(open) {
		this.setState({
			open,
		});
	}

	onMouseDownHandler() {
		this.setState({
			isMouseDown: true,
		});
	}

	onFocusHandler() {
		const { open, isMouseDown } = this.state;
		if (!open && isMouseDown) {
			this.setState({
				isMouseDown: false,
			});
		} else {
			this.setState({
				open: true,
			});
		}
	}

	renderCalendar() {
		let locale = enUS;
		const { appLanguage } = this.props;
		switch (appLanguage.language) {
		case 'zh-Hant':
			locale = zhTW;
			moment.locale('zh_TW');
			break;
		case 'zh-Hans':
			locale = zhCN;
			moment.locale('zh_CN');
			break;
		default:
			locale = enUS;
			moment.locale('en_US');
			break;
		}
		return (
			<Calendar
				locale={ locale }
				dateInputPlaceholder="YYYY-MM-DD"
				format={ this.dateFormat }
				showDateInput={ true }
				focusablePanel={ false }
			/>
		);
	}

	renderInput({ value }) {
		const { disabled } = this.state;
		return (
			<span
				tabIndex="0"
				onMouseDown={ this.onMouseDownHandler }
				onFocus={ this.onFocusHandler }
			>
				<input
					placeholder="YYYY-MM-DD"
					className="uk-input uk-width-1-1 uk-text-center"
					readOnly
					disabled={ disabled }
					value={ value && value.format(this.dateFormat) || '' }
				/>
				<div ref={ this.calendarContainerRef } />
			</span>
		);
	}

	render() {
		const { open, value } = this.state;
		return (
			<div style={{ position: 'relative' }}>
				<Picker
					align={{
						points: ['tc', 'tc']
					}}
					calendar={ this.renderCalendar() }
					value={ value }
					onChange={ this.onChangeHandler }
					getCalendarContainer={ this.getCalendarContainer }
					onOpenChange={ this.onOpenChangeHandler }
					open={ open }
				>
					{ this.renderInput }
				</Picker>
			</div>
		);
	}
}

DatePicker.propTypes = {
	value: PropTypes.instanceOf(Date),
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
	})
)(DatePicker);