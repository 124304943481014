import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import loc from 'Components/languages';
import Modal from 'Components/modal';
import LoadingOverlay from 'Components/loading-overlay';
import Button from 'Components/button';
import { getReturnItem } from 'Redux/actions';
import ReturnListItem from './ReturnListItem.jsx';
import styles from './ModalReturn.less';

class ModalReturn extends PureComponent {
	constructor() {
		super();
		this.state = {
			transactionItems: new List(),
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				height: '600px',
				padding: 0,
			},
		};
		this.isLoading = this.isLoading.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onClickSelectAllHandler = this.onClickSelectAllHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onClickCancelHandler = this.onClickCancelHandler.bind(this);
		this.onClickReturnHandler = this.onClickReturnHandler.bind(this);
	}

	componentDidMount() {
		const { isOpen, transactionNumber } = this.props;
		if (isOpen && transactionNumber) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		const transactionNumber = this.props.transactionNumber;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen && isOpen !== prevIsOpen && transactionNumber) {
			this.fetchData();
		}
	}

	isLoading() {
		const { returnItemInfo } = this.props;
		return returnItemInfo.isFetching;
	}

	fetchData() {
		const { transactionNumber } = this.props;
		const param = {
			payload: {
				transaction_number: transactionNumber,
			},
		};
		this.props.getReturnItem(param);
	}

	onClickSelectAllHandler() {
		const { returnItemInfo } = this.props;
		let { transactionItems } = this.state;
		if (!(returnItemInfo.data && returnItemInfo.data.transaction_items)) {
			return;
		}
		if (transactionItems.size === returnItemInfo.data.transaction_items.length) {
			transactionItems = new List();
		} else {
			transactionItems = new List(returnItemInfo.data.transaction_items);
		}
		this.setState({
			transactionItems,
		});
	}

	onSelectHandler(item) {
		let { transactionItems } = this.state;
		if (transactionItems.includes(item)) {
			transactionItems = transactionItems.remove(transactionItems.indexOf(item));
		} else {
			transactionItems = transactionItems.push(item);
		}
		this.setState({
			transactionItems,
		});
	}

	onClickCancelHandler() {
		const { onToggle } = this.props;
		onToggle();
	}

	onClickReturnHandler() {
		const { onReturn } = this.props;
		const { transactionItems } = this.state;
		transactionItems.forEach((item) => {
			item.item_quantity *= -1;
		});
		onReturn(transactionItems);
	}

	render() {
		const { isOpen, onToggle, returnItemInfo } = this.props;
		const { transactionItems } = this.state;
		const isLoading = this.isLoading();
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.return }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading }>
					<div className={ styles.selectAll } onClick={ this.onClickSelectAllHandler }>
						{
							returnItemInfo.data && returnItemInfo.data.transaction_items && transactionItems.size === returnItemInfo.data.transaction_items.length ? loc.deselectAll : loc.selectAll
						}
					</div>
					<div className={ styles.desc }>
						{ loc.returnPopupDesc }
					</div>
					<div className={ styles.container }>
						<div className={ styles.list }>
							<ul className="uk-list uk-list-divider">
								{
									returnItemInfo.data && returnItemInfo.data.transaction_items && returnItemInfo.data.transaction_items.map((item, index) => (
										<ReturnListItem
											key={ `modal-return-list-item-${index}` }
											item={ item }
											isSelected={ transactionItems.includes(item) }
											onSelect={ this.onSelectHandler }
										/>
									))
								}
							</ul>
						</div>
						<div className={ styles.buttons }>
							<Button
								theme="default"
								text={ loc.cancel }
								type="button"
								onClick={ this.onClickCancelHandler }
							/>
							<Button
								theme="primary"
								text={ loc.return }
								type="button"
								onClick={ this.onClickReturnHandler }
							/>
						</div>
					</div>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalReturn.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	transactionNumber: PropTypes.string,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
		popupReturnItem: state.popupReturnItem,
		returnItemInfo: state.returnItemInfo,
	}),
	(dispatch) => ({
		getReturnItem: para => dispatch(getReturnItem(para)),
	})
)(ModalReturn);