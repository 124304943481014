import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { convertFloatToPrice } from 'Helpers/helpers';
import loc from 'Components/languages';
import ListItem from 'Components/list-item';
import styles from './ReturnListItem.less';

class ReturnListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { onSelect, item } = this.props;
		onSelect(item);
	}

	render() {
		const { appLanguage, item, isSelected } = this.props;
		return (
			<ListItem
				title={ appLanguage.language === 'en' ? item.item_name_en : item.item_name_tc }
				onClick={ this.onClickHandler }
			>
				<div className={ styles.row }>
					<div className={ styles.column }>
						{ loc.itemCode }: { item.item_code }
					</div>
					<div className={ classnames(styles.column, styles.brand) }>
						{ loc.brandName }: { item.brand && (appLanguage.language === 'en' ? item.brand.name_en : item.brand.name_tc) }
					</div>
					<div className={ classnames(styles.column, styles.checkbox, 'uk-text-primary', 'uk-text-right') }>
						{
							isSelected && (
								<FontAwesomeIcon icon={ faCheck } className="fa-2x" />
							)
						}
					</div>
				</div>
				<div className={ styles.row }>
					<div className={ styles.column }>
						{ item.lot_no }
					</div>
					<div className={ styles.column }>
						{ loc.qty }: { item.item_quantity }
					</div>
					<div className={ styles.column }>
						{ loc.amount }: { convertFloatToPrice(item.final_amount) }
					</div>
					<div className={ styles.column } />
				</div>
			</ListItem>
		);
	}
}

ReturnListItem.propTypes = {
	item: PropTypes.object.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
	})
)(ReturnListItem);