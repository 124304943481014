exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".nrRMp {\n  color: #A89A72;\n  font-size: 14px;\n  padding: 15px 10px;\n  min-height: 42px;\n}\n.nrRMp ._2EPmA {\n  margin-top: 10px;\n  margin-bottom: -10px;\n}\n.nrRMp ._2EPmA ._2AxDz {\n  padding-left: 5px;\n}\n.nrRMp ._2EPmA ._1aQIs {\n  padding: 3px;\n  margin-top: -3px;\n  margin-bottom: -3px;\n}\n", ""]);

// Exports
exports.locals = {
	"summary": "nrRMp",
	"pagination": "_2EPmA",
	"page": "_2AxDz",
	"pageLink": "_1aQIs"
};