import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageOrderSearch from 'Pages/order-search';
import PageOrderView from 'Pages/order-view';

const OrderSearch = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/search`} component={ PageOrderSearch } />
			<Route exact path={`${match.url}/view/:transactionNumber`} component={ PageOrderView } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default OrderSearch;