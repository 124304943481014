import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';
import loc from 'Components/languages';
import styles from './Pagination.less';

class Pagination extends PureComponent {
	render() {
		const { data, children, onPageChange, forcePage } = this.props;
		if (Array.isArray(data)) {
			return (
				<div className={ styles.summary } />
			);
		}
		return (
			<div className={ styles.summary }>
				<div className={ styles.total }>
					{ children ? children : `${loc.totalResult}: ${data.total}` }
				</div>

				<div className={ styles.pagination }>
					<ReactPaginate
						previousLabel="<"
						nextLabel=">"
						containerClassName="uk-pagination uk-flex-center uk-margin-remove"
						activeClassName="uk-active"
						disabledClassName="uk-disabled"
						pageClassName={ classnames(data.isFetching && 'uk-disabled') }
						pageCount={ data.max_page }
						pageLinkClassName={ styles.pageLink }
						onPageChange={ onPageChange }
						forcePage={ forcePage }
					/>
				</div>
			</div>
		);
	}
}

Pagination.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	children: PropTypes.node,
	onPageChange: PropTypes.func.isRequired,
	forcePage: PropTypes.number,
};

export default Pagination;