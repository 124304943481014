exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1mojf {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  padding-bottom: 0;\n  padding-top: 5px;\n}\n._1mojf ._3XpKD {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  text-align: left;\n  text-transform: capitalize;\n}\n._1mojf ._3XpKD._34Opu {\n  margin-bottom: -8px;\n}\n._1mojf ._3XpKD._3XPYy {\n  -webkit-box-flex: 2;\n      -ms-flex: 2;\n          flex: 2;\n}\n", ""]);

// Exports
exports.locals = {
	"row": "_1mojf",
	"column": "_3XpKD",
	"checkbox": "_34Opu",
	"brand": "_3XPYy"
};