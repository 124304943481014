exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1Kjle {\n  padding-left: 20px;\n  padding-right: 20px;\n  line-height: 26px;\n  font-size: 15px;\n  color: #A0A0A0;\n  background-color: #EFF1F2;\n}\n._3404E {\n  padding-left: 20px;\n  padding-right: 20px;\n  height: 60px;\n  border-bottom: 0.5px solid #CBCBCB;\n}\n._3404E ._3dkvL {\n  line-height: 60px;\n}\n._3404E ._2giS0 {\n  padding-top: 12px;\n  padding-bottom: 12px;\n}\n._1BfWR {\n  padding: 15px 20px;\n  text-align: center;\n}\n._2_VAX {\n  text-transform: capitalize;\n}\n._11Whb {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  background-color: #fff;\n  border: 1px solid #cccccc;\n}\n", ""]);

// Exports
exports.locals = {
	"title": "_1Kjle",
	"row": "_3404E",
	"label": "_3dkvL",
	"value": "_2giS0",
	"button": "_1BfWR",
	"tabButton": "_2_VAX",
	"overlayWrapper": "_11Whb"
};