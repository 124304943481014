import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import loc from 'Components/languages';
import styles from './TabButton.less';

class TabButton extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		const { id, onChange } = this.props;
		e.preventDefault();
		onChange(id);
	}

	render() {
		const { id, active, className } = this.props;
		return (
			<div
				className={ classnames(styles.tab, active && styles.active, className) }
				onClick={ this.onClickHandler }
			>
				{ loc[id] || id }
			</div>
		);
	}
}

TabButton.propTypes = {
	id: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default TabButton;