exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2n78n ._1Mso5 {\n  word-wrap: break-word;\n  white-space: pre-wrap;\n  padding-right: 10px;\n}\n._2n78n .dHimb {\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n._2n78n ._38flb {\n  padding: 15px 10px;\n}\n._2n78n ._38flb ._2fhgv {\n  position: relative;\n  padding: 0 15px;\n  cursor: pointer;\n}\n._2n78n ._38flb .L9dTi {\n  margin-right: 20px;\n}\n._2n78n ._38flb .L9dTi ul {\n  padding-left: 0 !important;\n}\n", ""]);

// Exports
exports.locals = {
	"list": "_2n78n",
	"remark": "_1Mso5",
	"amount": "dHimb",
	"menu": "_38flb",
	"button": "_2fhgv",
	"dropdownMenu": "L9dTi"
};