import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TabButton from './TabButton.jsx';
import styles from './Tab.less';

class Tab extends PureComponent {
	render() {
		const { tabs, current, onChange, className, buttonClassName } = this.props;
		return (
			<div className={ classnames(styles.tabs, className) }>
				{
					tabs.map((tab) => (
						<TabButton
							key={ `tab-${tab}` }
							id={ tab }
							active={ current === tab }
							onChange={ onChange }
							className={ buttonClassName }
						/>
					))
				}
			</div>
		);
	}
}

Tab.propTypes = {
	tabs: PropTypes.array.isRequired,
	current: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
};

Tab.defaultProps = {
	className: '',
	buttonClassName: '',
};

export default Tab;